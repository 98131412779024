import React from 'react';
import { TextAreaForm } from "../../components"
import { TypingParagraph } from '../../utils';
import { TFunction } from 'i18next';


interface AreaProps {
    state: {
        autorisation: boolean;
        answer: string;
        interviewId: string;
    };
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    sendAnswer: (e: React.MouseEvent<HTMLButtonElement>, interviewwId: string | undefined, answer: string, idLastAnwser: string) => void;
    interviewwId: string | undefined;
    idLastAnwser: string;
    tag: () => boolean;
    t: TFunction;
    spinner: boolean;
    isInputBlocked: boolean;
    swimlanes: any[];
    role: string;
    time: Number
}

const Area = (props: AreaProps) => {
    const {
        state,
        handleChange,
        sendAnswer,
        interviewwId,
        idLastAnwser,
        tag,
        t,
        spinner,
        isInputBlocked,
        swimlanes,
        role,
        time,
    } = props

    const messages: string[] = [
        t('views.processInterview.s1', { time }),
        t('views.processInterview.s2', { time }),
        t('views.processInterview.s3', { time }),
    ];

    return (
        <div className="w-100 ">
            <div className='w-100'>
                {state.autorisation && tag() && !swimlanes[0]?.is_finish && swimlanes.length === 0 && (
                    <div className='w-100' >
                        <TextAreaForm
                            name={"area"}
                            sendButton={state.answer.trim().length > 0}
                            label=""
                            rows={1}
                            classNameArea={`w-100 bg-transparent text-white text-bg-dark py-2 px-3 rounded-${state.answer?.length < 100 ? 4 : 2} pe-5 border border-0`}
                            classNameContainer={`w-100 message-box rounded-${state.answer?.length < 100 ? 5 : 3}`}
                            placeholder={`${t('views.processInterview.yourResponse')}...`}
                            onChange={handleChange}
                            value={state.answer}
                            interviewId={state.interviewId}
                            sendAnswer={(e) => sendAnswer(e, interviewwId, state.answer, idLastAnwser)}
                            spinner={spinner}
                            maxRows={10}
                            minRows={1}
                            button={true}
                            readOnly={isInputBlocked}
                        />
                        <div className='pt-2 px-2 text-white opacity-75 d-flex flex-column justify-content-center align-items-center custom_fontSize'>
                            <span >{t('views.processInterview.pleaseEnsure')}</span>
                            <span >{t('views.processInterview.tip')}</span>
                        </div>
                    </div>
                )}
                {!swimlanes[0]?.is_finish && role === "ADMIN" && swimlanes.length > 0 && (
                    <div className="text-light opacity-50 w-100 mt-3">
                        <TypingParagraph
                            messages={messages}
                            delayBetweenMessages={8000}
                            typingSpeed={50}
                            className="fs-6 w-100"
                        />
                    </div>
                )}


            </div>

        </div>
    );
};

export default Area;
