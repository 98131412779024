// App Component

import { iUser } from "./type"

interface AvatarPros {
    link: string | undefined
    classSelector?: string
    width?: number
    height?: number
    user?: iUser
}

const Avatar = (props: AvatarPros) => {
    const { link, classSelector, width, height, user } = props;
    if (link === "") {
        return (
            <div style={{ width: width, height: height, backgroundColor: "#F0F0F0", objectFit: "fill" }} className={`rounded-circle border border-2 border-white fw-bold text-uppercase d-flex justify-content-center align-items-center `}>
                <span className='fb-color outline-none'>{user?.fullname?.slice(0, 2)}</span>
            </div>
        )

    } else {
        return (
            <img src={link && link} className={classSelector} alt="" style={{ width: width, height: height, objectFit: "cover", backgroundColor: "#F0F0F0" }} />
        )
    }
}

export default Avatar



