import React from 'react'
import { useTranslation } from 'react-i18next';

import SelectForm from './SelectForm'


type Options = {
    label: string;
    value: string;
};

interface InputProps {
    formClass?: string
    iconClass?: string
    iconName?: string
    inputClass?: string
    inputContainerClass?: string
    inputStyle?: React.CSSProperties
    inputType: string
    inputValue?: string
    onChange(event: React.ChangeEvent<HTMLInputElement>): any
    placeholderValue?: string
    iconContainer: string
    setFilter?: React.Dispatch<React.SetStateAction<string | undefined>>
    filter?: boolean
    fiterOption?: Options[]
}



const SearchInput = (props: InputProps) => {
    const { t } = useTranslation();
    const {
        formClass,
        iconClass,
        inputClass,
        inputContainerClass,
        inputStyle,
        inputType,
        inputValue,
        onChange,
        placeholderValue,
        iconContainer,
        setFilter,
        filter,
        fiterOption
    } = props

    return (
        <div className={`${inputContainerClass}`}>
            <div className={`input-group mb-3 form-control ${formClass}`} style={inputStyle}>
                <span className={`input-group-text ${iconContainer}`} id="basic-addon1"><i className={`${iconClass}`}></i></span>
                <input onChange={onChange} type={inputType} className={`custom-input w-75 ${inputClass}`} placeholder={placeholderValue} value={inputValue} aria-label="Username" aria-describedby="basic-addon1" />
                {filter &&
                    <SelectForm
                        selectClass="bg-dark px-1 py-1 m-0 border-0 bg-black h-100  border border-0"
                        placeholder={t('words.filter')}
                        selectName='selectName'
                        selectOptions={fiterOption ?? []}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilter?.(event.target.value)}
                    />
                }
            </div>
        </div>
    )
}
export default SearchInput