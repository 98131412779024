import { RouterProvider } from "react-router-dom"

import { NavigationApp } from './routes'
import React from "react";
import { i18n } from "./services";

function App() {
    const [currentLanguage, setCurrentLanguage] = React.useState(() => {
        try {
            return localStorage.getItem('currentLanguage') || navigator.language;
        } catch (error) {
            return navigator.language;
        }
    });
    React.useEffect(() => {
        changeLanguage(currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLanguage]);

    const changeLanguage = (language: string) => {
        let updateLanguage = language === 'fr-FR' || language === 'fr' ? 'fr' : 'en';
        i18n.changeLanguage(updateLanguage);
        setCurrentLanguage(updateLanguage);
        localStorage.setItem('currentLanguage', updateLanguage);
    };
    return (
        <RouterProvider router={NavigationApp} />
    )
}


export default App
