import React from 'react'
import { RenderFormatteContent } from '../../utils'

interface iprops {
    fullname: string
    content: string
}
const contentQuestion = (props: iprops) => {
    const { fullname, content } = props
    return (
        <div className="mt-4">
            <div className="d-flex align-items-center">
                <i className="fa-solid fa-user fs-6 text-light"></i>
                <span className="ps-1 text-light fw-bold">{fullname}</span>
            </div>
            <p className="fs-6 ps-4 text-light fw-lighter costom-control">{RenderFormatteContent(content)}</p>
        </div>
    )
}
export default contentQuestion