import React from "react";
import { Spiner } from "../../../components";


interface childProps {
  onClick?: () => void,
  submit?: boolean
  name: string
  isDisabled?: boolean
  className: string

}

const Button = (props: childProps) => {
  const { onClick, submit, name, isDisabled, className } = props

  return (
    <div className="me-2">
      <button
        disabled={isDisabled}
        className={`${className} ${isDisabled ? "opacity-50 disabled " : ""}`}
        onClick={onClick}
        style={isDisabled ? { cursor: 'not-allowed' } : { cursor: 'pointer' }}
      >
        {submit ?
          <Spiner className="spinner-border-sm text-white" />
          :
          <>{name}</>
        }
      </button>
    </div>
  );
}


export default Button;
