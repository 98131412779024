// React Component
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

// App Component
import { Buttons } from '../../components'
import { AuthLayout } from '../../layouts'
import { Routes } from '../../routes'
import { useAppDispatch } from '../../stores';
import { login } from '../../stores';
import { iSessions } from '../../features/auth';
import { FamlaServices } from '../../services';

//interface
interface ChildProps {
    google: boolean
}

const AuthViewComponent = ({ google }: ChildProps) => {
    console.log('google===', google);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const authNavigation = (path: string) => {
        if (path === "login") {
            navigate(Routes.AUTH_LOGIN)
        }
        else {
            navigate(Routes.AUTH_SIGNIN)
        }
    }

    const getCookie = (name: string) => {
        return Cookies.get(name);
    };

    const loadSession = useCallback(async (token: string) => {
        try {
            let data = await FamlaServices("api_host", `/auth/me`, "GET", "", token);
            if (data.status === 200) {
                const session: iSessions = data.body.data;
                if (session.company_id === null) {
                    navigate(Routes.CREATE_MY_COMPANY)
                } else {
                    dispatch(login({ token: token, session: session, user: session.user }));
                    navigate(Routes.DASHBOARD);
                }
            } else {
                if (google) {
                    window.location.reload()
                }
            }
        } catch (e) {
            return false;
        }

    }, [dispatch, google, navigate]);

    React.useEffect(() => {
        const accessToken = getCookie('access_token');
        if (accessToken && google) {
            loadSession(accessToken)
        }
        console.log('relod===');
    }, [google, loadSession])


    return (
        <AuthLayout>
            <h4 className=" text-light fw-bold">{t('views.auth.getStarted')}</h4>
            <Buttons
                name={t('views.auth.logIn')}
                onClick={() => authNavigation("login")}
                isLoading={false}
                btnClass="btn-theme w-50 mt-4"
                labelClass="text-white fw-bold"
                styleProps={{ height: 45 }}
                arrowVisible={false}
                arrowColor=''
            />

            <Buttons
                name={t('views.auth.signUp')}
                onClick={() => authNavigation("signin")}
                isLoading={false}
                btnClass="btn-theme w-50 mt-4"
                labelClass="text-white fw-bold"
                styleProps={{ height: 45 }}
                arrowVisible={false}
                arrowColor=''
            />
            <div className='mt-mob'></div>
        </AuthLayout>
    )
}
export default AuthViewComponent