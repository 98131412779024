// React Compoment
import React from 'react'

// Features
import { Routes } from '../../routes'
import { UsersPages } from '../../features/dashboard'

// Layout
import DashLayout from '../../layouts/dashboard/dashLayout'

const UsersView = () => {
    const pathname = window.location.pathname

    return (
        <DashLayout>
            {pathname === Routes.USERS_LIST ?
                <UsersPages />
                :
                <UsersPages />
            }

        </DashLayout>
    )
}

export default UsersView;