// Reac Component
import React from "react";

// App Component
import { Avatar } from "../../components";
import { } from "../../routes"
import { iUser } from "../../components/type";
import { formatDateTime } from "../../utils";
import { GenericModal } from "../../views/modal";
import { useTranslation } from "react-i18next";

interface FeaturesProps {
    loading: boolean
    featuresDatas: iUser[]
    context?: string
    deleteCompany?: (id?: string) => void
    listType?: 'USERS' | 'COLLABORATORS' | 'ADMIN' | 'OWNER'
}

function UsersList(props: FeaturesProps) {
    const { loading, featuresDatas, deleteCompany, listType } = props;
    const { t } = useTranslation()
    return (
        <>
            <table className="table mb-0 text-nowrap table-sm table-hover text-secondary ">
                <thead className="table-light bg-white">
                    <tr>
                        <th className="border-0"></th>
                        <th className="border-0">{t('words.name')}</th>
                        {listType === "USERS" &&
                            <th className="border-0">{t('words.role')}</th>
                        }
                        <th className="border-0">{t('words.email')}</th>
                        <th className="border-0">{t('dashboard.registrationDate')}</th>
                        <th className="border-0">{t('dashboard.lastConnectionDate')}</th>
                        <th className="border-0"></th>
                    </tr>
                </thead>
                <tbody>
                    {loading &&
                        <tr>
                            <td colSpan={5} className="text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </td>
                        </tr>
                    }
                    {!loading && featuresDatas.length <= 0 &&
                        <tr>
                            <th colSpan={5} className="text-center">{t('navigation.noData')}</th>
                        </tr>
                    }
                    {!loading &&
                        featuresDatas.map((data) => (
                            <tr key={data._id}>
                                <td className="align-middle border-top-0 fw-bold">
                                    <Avatar link={""} user={data} height={40} width={40} classSelector="rounded-pill" />
                                </td>
                                <td className="align-middle border-top-0 fw-bold">
                                    {data.fullname}
                                </td>
                                {listType === "USERS" &&
                                    <td className="align-middle border-top-0 fw-bold">
                                        {data?.role}
                                    </td>
                                }
                                <td className="align-middle border-top-0 fw-bold">
                                    {data.email}
                                </td>

                                <td className="align-middle border-top-0 fw-bold">
                                    {formatDateTime(data.created_at)}
                                </td>

                                <td className="align-middle border-top-0 fw-bold">
                                    {formatDateTime(data?.last_connection ?? "")}
                                </td>
                                {/* <td className="text-muted align-middle text-end">
                                    <Link to={`${Routes.USERS_DETAILS}/${data._id}`} className="btn btn-dark btn-xs">
                                        More <i className="fe fe-more-horizontal ms-2"></i>
                                    </Link>
                                </td> */}

                                {listType !== 'ADMIN' &&
                                    <td className="text-muted align-middle text-end">
                                        <button className="btn btn-theme btn-sm border-0  btn-xs" data-bs-toggle="modal" data-bs-target={`#generic${data._id}`}>
                                            {t('words.delete')}<i className="fa-solid fa-trash ms-2 text-white small"></i>
                                        </button>
                                    </td>
                                }
                                <td>
                                    <GenericModal
                                        sentence="Are you sure you want to permanently delete this user from the system? This action cannot be undone."
                                        actionButton="Delete user"
                                        action={(id?: string) => deleteCompany?.(data._id)}
                                        title="Delete User"
                                        processId={data._id}
                                        classNameSentence="text-theme"

                                    />

                                </td>


                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    )
};

export default UsersList;