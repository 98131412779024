//React
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

//component
import { useAppSelector } from "../../stores"
import { FamlaServices } from '../../services'
import { InputForm, Spiner } from '../../components'


//interface
import { iAlert, iProject } from '../../interfaces'
import { iProcess } from '../../components/type';



interface ChildProps {
    processId: string
    processName: string
    description?: string
    setProject: React.Dispatch<React.SetStateAction<iProject[]>>;
    setProcess: React.Dispatch<React.SetStateAction<iProcess[]>>;
    folder: boolean
}


const EditprocessModal = (props: ChildProps) => {
    const { processId, processName, description, setProject, folder, setProcess } = props
    const closeModal = useRef<HTMLButtonElement>(null);
    const users = useAppSelector(state => state.auth)
    const { t } = useTranslation();
    const [state, setState] = React.useState({
        processName: processName,
        isSubmit: false,
        role: "",
        description: description
    })

    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    })



    const handleChange = (event: any) => {
        const { type, name, value, checked } = event.target;
        let fieldValue = type === 'checkbox' ? checked : value;

        if (type === 'number') {
            fieldValue = Number(fieldValue);
        }
        if (name === 'name') {
            setState((prev) => ({ ...prev, processName: fieldValue }))
        } else if (name === "description") {
            setState((prev) => ({ ...prev, description: fieldValue }))
        }
    };


    const handleSubmit = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (state.processName.length === 0) {
            const msg: string = folder ? t('navigation.enterFolderName') : t('navigation.enterProcessName')
            setAlert({ isActive: true, type: 'danger', message: msg });
            return;
        }


        setState({ ...state, isSubmit: true });

        let form = {
            title: state.processName,
            description: state.description,
            updated_at: Date.now()
        };

        const endpoint: string = folder ? `/project/${processId}` : `/process/update/name/${processId}`;

        let data = await FamlaServices("api_host", `${endpoint}`, "PUT", form, users.access_token);

        console.log('====', data);

        if (data.status === 200) {
            const msgAlert: string = folder ? t("navigation.folderSuccessfully") : t("navigation.processSuccessfully")
            setAlert({ isActive: true, type: 'success', message: msgAlert });
            setState((prevState) => ({ ...prevState, isSubmit: false, role: "", email: "" }));
            const endpoint: string = folder ? `/project/my` : `/process/my`
            let datas = await FamlaServices("api_host", endpoint, "GET", "", users.access_token);
            if (datas.status === 200) {
                if (folder) {
                    setProject(datas.body.data)
                } else {
                    setProcess(datas.body.data)
                }

            }
            if (closeModal.current) {
                closeModal.current.click();
            }
        } else if (data.body.status_code === 400) {
            const msg: string = folder ? t("navigation.youAreNotAuthorized") : t("navigation.interviewNotOver")
            setAlert({ isActive: true, type: 'danger', message: msg });
        }
        else if (data.body.status_code === 404) {
            const msg: string = folder ? t("navigation.folderNotFound") : t("navigation.processNotFound")
            setAlert({ isActive: true, type: 'danger', message: msg });
        }
        else if (data.body.status_code === 401) {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.youAreNotLoggedIn") });
        }
        else {
            setAlert({ isActive: true, type: 'danger', message: t("navigation.anErrorOcured") });
        }
        setState({ ...state, isSubmit: false });
    };

    return (
        <div className="modal" id={`edit${processId}`} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content bg-dark ">
                    <div className="modal-header border-0">
                        <h5 className="modal-title text-white">{t('words.rename')}</h5>
                        <button type="button" className="btn-close bg-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    {!folder && <p className='text-theme px-3'>{t('navigation.processchange')}</p>}
                    <div className="modal-body">
                        {alert.isActive &&
                            <div className={`alert alert-${alert.type}`} role="alert">
                                {alert.message}
                            </div>
                        }
                        <InputForm
                            inputType="text"
                            inputLabel={t('words.name')}
                            inputName="name"
                            inputValue={state.processName}
                            isFloatingLabel={true}
                            labelClass="text-theme"
                            onChange={(e) => handleChange(e)}
                            inputContainerClass={'mb-3 p-0'}
                            inputClass="bg-dark text-light text-bg-dark"
                            inputStyle={{ height: 0, padding: 0 }}
                        />
                        <InputForm
                            inputType="text"
                            inputLabel={t('words.description')}
                            inputName="description"
                            inputValue={state.description || description}
                            isFloatingLabel={true}
                            labelClass="text-theme"
                            onChange={(e) => handleChange(e)}
                            inputContainerClass={'mb-3 p-0'}
                            inputClass="bg-dark text-light text-bg-dark"
                            inputStyle={{ height: 0, padding: 0 }}
                        />

                    </div>
                    <div className="modal-footer">
                        <button ref={closeModal} type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('words.close')}</button>
                        <button
                            type="button"
                            onClick={(e) => handleSubmit(e)}
                            className="btn btn-primary"
                        >{state.isSubmit ? <Spiner className='spinner-border-sm' /> : <>{t('words.edit')}</>}</button>
                    </div>
                </div>
            </div>
        </div>


    )
}
export default EditprocessModal