// Reac Component
import React from "react";
import { useNavigate } from "react-router-dom";

// App Component
import { Routes } from "../../routes";
import { BreadCrumb } from "../../components";
import { FamlaServices } from "../../services";

// Features
import UsersList from "./usersList";
import { logout, useAppDispatch, useAppSelector } from "../../stores";

//interface
import { iAlert } from "../../interfaces";
import { iUser } from "../../components/type";

//utils
import useSearch from "../../utils/useSearch";
import { useTranslation } from "react-i18next";

const UsersPages = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const auth = useAppSelector(state => state.auth);
    const [users, setUsers] = React.useState<iUser[]>([]);
    const companyId = auth.session.company_id;
    const [loading, setLoading] = React.useState(false);
    // const [filter, setFilter] = React.useState<string | undefined>('USER');
    const [alert, setAlert] = React.useState<iAlert>({
        isActive: false,
        type: '',
        message: ''
    });

    const [pagination, setPagination] = React.useState({
        total: 0,
        per_page: 10,
        last_page: 0,
        current_page: 1,
    });

    const { searchQuery, setSearchQuery, searchResults, searchLoad } = useSearch(`/users/search/my/user`, companyId);

    const logOut = () => {
        dispatch(logout());

        navigate(`${Routes.AUTH_LOGIN}?status=10`);
    };


    async function loadUsers(currentPage: number, per_page: number) {
        setLoading(true);

        try {
            setAlert({ isActive: false, type: '', message: '' });
            let data = await FamlaServices("api_host", `/users/company/${companyId}?page=${currentPage}&limit=${per_page}`, "GET", "", auth.access_token);

            if (data.status === 200) {
                const paginage = data.body.paginage;

                const workersWithRole = data.body.data.worker.map((user: iUser) => ({ ...user, role: "worker" }));
                const ownersWithRole = data.body.data.owner.map((user: iUser) => ({ ...user, role: "owner" }));
                const adminsWithRole = data.body.data.admin.map((user: iUser) => ({ ...user, role: "admin" }));

                const users: iUser[] = [
                    ...workersWithRole,
                    ...ownersWithRole,
                    ...adminsWithRole,
                ];

                setUsers(users);
                setPagination({
                    ...pagination,
                    current_page: paginage.current_page,
                    total: paginage.total,
                    last_page: paginage.last_page
                });
            } else if (data.status === 401 && data.body.description === "You do not have authorisation to continue") {
                setAlert({ isActive: true, type: 'warning', message: 'You do not have authorisation to continue' });
            } else if (data.status === 401) {
                logOut();
            } else {
                setAlert({ isActive: true, type: 'danger', message: 'An error has occurred, please try again later' });
            }
        } catch (e) {
            if (e instanceof Error) {
                console.error(e.message);
            }
        } finally {
            setLoading(false);
        }
    }


    React.useEffect(() => {
        loadUsers(pagination.current_page, pagination.per_page);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.access_token]);



    const handleNextPage = () => {
        if (pagination.current_page < pagination.last_page) {
            setPagination((prev) => ({ ...prev, current_page: prev.current_page + 1 }));
            loadUsers(pagination.current_page + 1, pagination.per_page);
        }
    };

    const handlePrevPage = () => {
        if (pagination.current_page > 1) {
            setPagination((prev) => ({ ...prev, current_page: prev.current_page - 1 }));
            loadUsers(pagination.current_page - 1, pagination.per_page);
        }
    };

    const breadCrumb = [
        {
            name: t("dashboard.dashboard"),
            link: Routes.DASHBOARDS,
            isActive: false
        },
        {
            name: t("dashboard.users"),
            link: Routes.USERS_LIST,
            isActive: false
        },
        {
            name: t("dashboard.list"),
            link: "",
            isActive: true
        }
    ];

    // const filterOption = [
    //     { label: "USER", value: "USER" },
    // ]

    const deleteUser = async (userId?: string) => {
        let data = await FamlaServices("api_host", `/users/remove/${userId}`, "PUT", "", auth.access_token);
        if (data.status === 200) {
            // setAlert({ ...alert, type: "success", message: t('navigation.userSuccessfullyDeleted') })
            setUsers(users.filter((user: iUser) => user._id !== userId));


        }
    }

    return (
        <div className="container-fluid p-4">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                    <BreadCrumb name={t("dashboard.users")} links={breadCrumb} children={<></>} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-12">
                    <div className="card rounded-3">
                        <div className="p-4 row">
                            <form className="d-flex align-items-center col-12 col-md-6 col-lg-3 col-xl-3 ">
                                <span className="position-absolute ps-3 search-icon">
                                    <i className="fa-solid fa-search"></i>
                                </span>
                                <input
                                    type="search"
                                    name="search"
                                    className="form-control ps-5 text-secondary"
                                    placeholder="Users"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
                                />
                                {/* <SelectForm
                                    selectClass=" px-1 py-1 m-0 h-100 ms-2"
                                    placeholder={'filter'}
                                    selectName='selectName'
                                    selectOptions={filterOption ?? []}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilter(event.target.value)}
                                /> */}
                            </form>
                            {alert.isActive &&
                                <div className={`alert alert-${alert.type} mb-0 col-12 col-md-6 col-lg-6 offset-lg-3 my-2`} role="alert">
                                    {alert.message}
                                </div>
                            }
                        </div>
                        <div className="table-responsive border-0">
                            {searchQuery === "" ?
                                <UsersList
                                    loading={loading}
                                    featuresDatas={users}
                                    context="user"
                                    key={1}
                                    listType="USERS"
                                    deleteCompany={deleteUser} />
                                :
                                <UsersList
                                    loading={!searchLoad}
                                    featuresDatas={searchResults}
                                    context="user"
                                    key={2}
                                    listType="USERS"
                                    deleteCompany={deleteUser}
                                />
                            }
                        </div>
                        <div className="card-footer border-top-0 bg-white">
                            <nav aria-label="Page navigation example">
                                <ul className="pagination mb-0 justify-content-end">
                                    <li className={`page-item ${pagination.current_page === 1 ? 'disabled' : ''}`}>
                                        <button className="page-link mx-1 rounded" onClick={handlePrevPage}>
                                            <i className="fa-solid fa-chevron-left"></i>
                                        </button>
                                    </li>
                                    <li className={`page-item  ${pagination.current_page === 1 ? 'active' : ''}`} onClick={() => { loadUsers(1, pagination.per_page) }}>
                                        <span className="page-link mx-1 rounded cursor-pointer" >1</span>
                                    </li>
                                    <li className={`page-item  ${pagination.current_page === 2 ? 'active' : ''}`} onClick={() => { loadUsers(2, pagination.per_page) }}>
                                        <span className="page-link mx-1 rounded cursor-pointer" >2</span>
                                    </li>
                                    <li className={`page-item  ${pagination.current_page === 3 ? 'active' : ''}`} onClick={() => { loadUsers(3, pagination.per_page) }}>
                                        <span className="page-link mx-1 rounded cursor-pointer" >3</span>
                                    </li>
                                    <li className={`page-item ${pagination.current_page >= pagination.last_page ? 'disabled' : ''}`}>
                                        <button className="page-link mx-1 rounded bg-transparent" onClick={handleNextPage} disabled={pagination.current_page >= pagination.last_page}>
                                            <i className="fa-solid fa-chevron-right"></i>
                                        </button>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default UsersPages;