// React Component
import React from 'react';
import { AuthViewComponent } from '../../components';

const OauthView = () => {
    const google: boolean = true
    return (
        <AuthViewComponent google={google} />
    )
}
OauthView.displayName = "AuthView";
export default OauthView