import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppLayout } from '../../layouts'
import { Images } from '../../constants'
import { Link } from 'react-router-dom'
import { Routes } from '../../routes'


const Faq = () => {
    const { t } = useTranslation();
    return (
        <AppLayout>
            <div className="container-fluid fixed-top mb-3 bg-black d-flex w-100">
                <div className="text-white fs-5 mb-0  pt-2 " style={{ height: '50px' }}> <span className=""> <img className='img-fluid' src={Images.logov1} alt="" style={{ height: '30px' }} /></span></div>
            </div>
            <span className="mb-5 text-black">.</span>
            <div className="container align-items-center my-5">
                <div className="container align-items-center my-5">
                    <Link to={Routes.DASHBOARD} className="text-light fw-bold fs-4 text-decoration-none text-uppercase">
                        <div className="d-flex">
                            <span><i className="fa-solid fa-arrow-left"></i></span>
                            <p className="ms-1">{t('navigation.backHome')}</p>
                        </div>
                    </Link>
                    <h6 className="fw-blod fs-4  pb-3 text-theme bg-black pt-0   text-uppercase">faq</h6>
                    <ol className="fs-6 text-white p-4">
                        <li className="text-white fs-4">{t('views.legalDocs.faq.t1')}</li>
                        <p className="flex-wrap">{t('views.legalDocs.faq.p1')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.faq.t2')}</li>
                        <p className="flex-wrap">{t('views.legalDocs.faq.p2')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.faq.t3')} </li>
                        <p className="flex-wrap">{t('views.legalDocs.faq.p3')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.faq.t4')}</li>
                        <p className="flex-wrap">{t('views.legalDocs.faq.p4')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.faq.t5')}</li>
                        <p>{t('views.legalDocs.faq.p5')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.faq.t6')}</li>
                        <p>{t('views.legalDocs.faq.p6')}</p>
                        <li className="text-white mt-3 fs-4 ">{t('views.legalDocs.faq.t7')}</li>
                        <p> {t('views.legalDocs.faq.p7')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.faq.t8')} </li>
                        <p>{t('views.legalDocs.faq.p8')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.faq.t9')} </li>
                        <p>{t('views.legalDocs.faq.p9')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.faq.t10')} </li>
                        <p>{t('views.legalDocs.faq.p10')} <a href={Routes.CONTACTUS}>{t('views.legalDocs.faq.contactUsPage')}</a> </p>
                    </ol>
                    <p className='text-white pb-5'>{t('views.legalDocs.faq.p11')}</p>
                </div>
            </div >
        </AppLayout >
    )
}
export default Faq