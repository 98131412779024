import React from 'react';
import { slice } from '../../utils';
import { TFunction } from 'i18next';

interface Swimlane {
    name: string;
    _id: string;
}

interface ChildProps {
    closeRef: React.RefObject<HTMLButtonElement>;
    allSwimLane: Swimlane[];
    setMap: (id: string) => void;
    t: TFunction
}

const DiagramList: React.FC<ChildProps> = (props) => {
    const { closeRef, allSwimLane, setMap, t } = props;

    return (
        <div className="modal" id="mapModal" tabIndex={-1} style={{ width: '300px' }}>
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content bg-black text-white">
                    <div className="modal-header py-2">
                        <h5 className="modal-title">{t('features.swimlane.versionHistory')} </h5>
                        <button ref={closeRef} type="button" className="btn-close bg-white fs-6" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body px-1 tex-white">
                        <div className='d-flex flex-column align-items-start'>
                            {allSwimLane.map((swimLane) => (
                                <button
                                    key={swimLane._id}
                                    className="btn btn-outline-dark text-white border-0 py-0 mb-2"
                                    onClick={() => setMap(swimLane._id)}
                                >
                                    {slice(swimLane.name ? swimLane.name : swimLane._id, 25)}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DiagramList;
