//React
import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

//App
import { Routes } from "../../routes"



const HeaderInterview = () => {
    const { t } = useTranslation()
    return (
        <div className="m-3  d-flex justify-content-between">
            <Link to={Routes.DASHBOARD} className="text-light fw-bold fs-5 text-decoration-none m-none">
                <div className="d-flex">
                    <span><i className="fa-solid fa-arrow-left"></i></span>
                    <p className="ms-1 text-uppercase">{t('navigation.backHome')}</p>
                </div>
            </Link>

            <button className="web-none text-decoration-none bg-dark border border-white rounded-2" data-bs-toggle='offcanvas' data-bs-target='#offcanvas'> <i className="fa-solid fa-bars text-white"></i></button>
        </div>
    )
}

export default HeaderInterview