// React Component
import React from 'react'
import { useTranslation } from 'react-i18next'

// App Component
import { CardProcess, Spiner } from '../../components'
import { AppLayout, HeaderLayout, ProcessLayout } from '../../layouts'
import { FamlaServices } from '../../services'
import { useAppSelector } from '../../stores'

// Features

//Interface
import { iProcess } from '../../components/type'

const AllMAp = () => {
    const users = useAppSelector((state) => state.auth);
    const { t } = useTranslation();
    const language = localStorage.getItem('currentLanguage') === "fr" ? "french" : "english"


    const [state, setState] = React.useState({
        isLoadingProcess: true,
        error: '',
        popularUseCase: []
    })


    React.useEffect(() => {

        const loadPopularUseCase = async () => {
            try {
                let data = await FamlaServices("api_host", `/popular/${language}`, "GET", "", users.access_token);
                if (data.status === 200) {
                    setState((prev) => ({ ...prev, popularUseCase: data.body.data, isLoadingProcess: false }));
                } else {
                    setState((prev) => ({ ...prev, isLoadingProcess: false, error: 'Failed to fetch data' }));
                }
            } catch (error: any) {
                setState((prev) => ({ ...prev, isLoadingProcess: false, error: error.message }));
            }
        }

        loadPopularUseCase();

    }, [language, users.access_token])



    return (
        <AppLayout>
            <div className="container-fluid mt-3 pb-3 px-5">
                <HeaderLayout loadcompany={false} />
                <div className="mt-4 m-auto d-flex align-items-center justify-content-center w-75">

                </div>

                <ProcessLayout title={t('views.processView.popularUseCase')} arrowBack={true} style={{}} addClassName=' containerCardProcess overflow-auto'>
                    {state.isLoadingProcess ?
                        <Spiner className='ms-5' /> :
                        <>
                            {state.popularUseCase.map((popularUseCase: iProcess) => {
                                return (
                                    <CardProcess
                                        processName={popularUseCase.title}
                                        thisProcessHasABadge={false}
                                        theBadgeHasAValue={false}
                                        badgeValue={0}
                                        thisProcessHasAOwner={false}
                                        owner=''
                                        key={popularUseCase._id}
                                        route=''
                                        deleteProcess={() => { }}
                                        description={popularUseCase.description}
                                        processId={''}
                                        folder={false}
                                        setProject={() => { }}
                                        usage={'populare'}
                                        setProcess={() => { }}
                                    />
                                )
                            })}
                        </>
                    }

                </ProcessLayout>
            </div>
        </AppLayout>
    )
}

export default AllMAp