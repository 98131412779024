import { useState, useEffect } from 'react';

function useCountdown(expirationAt: string | undefined): number {
    const [daysRemaining, setDaysRemaining] = useState<number>(0);

    useEffect(() => {
        const calculateDaysRemaining = () => {
            const expirationDate = new Date(expirationAt ?? "");

            if (isNaN(expirationDate.getTime())) {
                setDaysRemaining(0);
                return;
            }

            const now = new Date();
            const timeLeft = expirationDate.getTime() - now.getTime();

            if (timeLeft > 0) {
                const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
                setDaysRemaining(daysLeft);
            } else {
                setDaysRemaining(0);
            }
        };

        calculateDaysRemaining();
        const intervalId = setInterval(calculateDaysRemaining, 1000 * 60 * 60);

        return () => clearInterval(intervalId);
    }, [expirationAt]);

    return daysRemaining;
}

export default useCountdown;
