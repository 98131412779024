//React
import React, { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//APP
import { DefaultEditorSwimlane } from "../../swimLane";
import { DiagramList } from "../../../views/modal";
import { FamlaServices } from "../../../services";
import { simlane_export } from "../../swimLane/static/data";
import { SwimlaneError } from "../../../components";
import { useAppSelector } from "../../../stores";
import '../../../../src/assets/css/diagram.css'



const ProcessDiagram = () => {
  const { id, sId } = useParams();
  const { t } = useTranslation();
  const [swimLane, setSwimlane] = React.useState(simlane_export);
  const [allSwimLane, setAllSwimLane] = React.useState([]);
  const [approvallist, setApprovallist] = React.useState([]);
  const users = useAppSelector((state) => state.auth);
  const [spinner, setSpinner] = React.useState(true);
  const [time, setTime] = React.useState(0);
  const [key, setKey] = React.useState(0);
  const closeRef = useRef<HTMLButtonElement>(null);
  const [isMapGenerationFailed, setIsMapGenerationFailed] = React.useState(false)
  const navigate = useNavigate()

  const loadAllSwimlane = async () => {
    let data = await FamlaServices("api_host", `/mapping/process/${id}`, "GET", "", users.access_token);
    if (data.status === 200) {
      setAllSwimLane(data.body.data);
    }
  };

  React.useEffect(() => {
    const loadSwimlane = async () => {
      let data = await FamlaServices("api_host", `/mapping/swimlane/${sId}`, "GET", "", users.access_token);
      if (data.status === 200) {
        setSwimlane(data.body.data);
        setTimeout(() => {
          setSpinner(false);
        }, 20000);
      } else if (data.body.status_code === 400) {
        setIsMapGenerationFailed(true)
        setSpinner(false);
      }
    };
    loadSwimlane();


    loadAllSwimlane();

    const loadApprovalList = async () => {
      try {
        let data = await FamlaServices("api_host", `/approval/`, "GET", "", users.access_token);
        if (data.status === 200) {
          setApprovallist(data.body.data)
        }
      } catch (error: any) {

      }
    }

    loadApprovalList();

    const loadTime = async () => {
      let data = await FamlaServices("api_host", `/stats/process/${id}`, "GET", "", users.access_token);
      if (data.status === 200) {
        setTime(data.body.data);
      }
    };
    loadTime();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, sId, users.access_token]);

  async function setMap(_id: string) {
    let data = await FamlaServices("api_host", `/mapping/swimlane/${_id}`, "GET", "", users.access_token);
    if (data.status === 200) {
      setSwimlane(data.body.data);
      setKey(key => key + 1)
      if (closeRef?.current) {
        closeRef.current?.click();
      }

    }
  }

  const refrech = () => {
    console.log("============navigate");
    // window.location.reload();
    navigate(-1);

  }


  return (
    < >
      <div key={key}>
        {spinner ?
          (
            <SwimlaneError refresh={() => { }} error={false} t={t} time={time} />
          ) :
          (swimLane.length === 0 || isMapGenerationFailed ? (
            <SwimlaneError refresh={refrech} error={true} t={t} />
          ) :
            (
              <DefaultEditorSwimlane
                swimlaneExport={swimLane}
                id={id}
                allSwimLane={allSwimLane}
                sId={sId}
                t={t}
                approvallist={approvallist}
                loadAllSwimlane={loadAllSwimlane}
              />
            )
          )
        }
      </div>
      <DiagramList closeRef={closeRef} allSwimLane={allSwimLane} setMap={setMap} t={t} />
    </>
  );
};
export default ProcessDiagram;
