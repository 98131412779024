const DefaultsSettingForShapesType = {
    circle: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 90,
        height: 90,
        text: "Circle",
        fontColor: "#FFFFFF"
    },
    collate: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Collate",
        fontColor: "#FFFFFF"
    },
    // comment: {
    //     fill: "#C7755B",
    //     stroke: "#C7755B",
    //     strokeWidth: 2,
    //     width: 140,
    //     height: 90,
    //     text: "Comment",
    //     fontColor: "#FFFFFF"
    // },
    data: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Data",
        fontColor: "#FFFFFF"
    },
    database: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Database",
        fontColor: "#FFFFFF"
    },
    dash: {
        strokeWidth: 3,
        stroke: "#245CE0"
    },
    // decision: {
    //     fill: "#C7755B",
    //     stroke: "#C7755B",
    //     strokeWidth: 2,
    //     width: 140,
    //     height: 90,
    //     text: "Decision",
    //     fontColor: "#FFFFFF"
    // },
    delay: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Delay",
        fontColor: "#FFFFFF"
    },
    display: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Display",
        fontColor: "#FFFFFF"
    },
    document: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Document",
        fontColor: "#FFFFFF"
    },
    endpoint: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Endpoint",
        fontColor: "#FFFFFF"
    },
    end: {
        fill: "#616161",
        stroke: "#616161",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "End",
        fontColor: "#FFFFFF"
    },
    extract: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Extract",
        fontColor: "#FFFFFF"
    },
    input: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Input",
        fontColor: "#FFFFFF"
    },
    internal: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Internal",
        fontColor: "#FFFFFF"
    },
    junction: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 90,
        height: 90,
        text: "Junction",
        fontColor: "#FFFFFF"
    },
    keyring: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Keyring",
        fontColor: "#FFFFFF"
    },
    // line: {
    //     strokeWidth: 3,
    //     stroke: "#245CE0"
    // },
    offline: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Offline",
        fontColor: "#FFFFFF"
    },
    operation: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Operation",
        fontColor: "#FFFFFF"
    },
    or: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 90,
        height: 90,
        text: "Or",
        fontColor: "#FFFFFF"
    },
    output: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Output",
        fontColor: "#FFFFFF"
    },
    page: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Page",
        fontColor: "#FFFFFF"
    },
    preparation: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Preparation",
        fontColor: "#FFFFFF"
    },
    process: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Process",
        fontColor: "#FFFFFF"
    },
    punchcard: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Punchcard",
        fontColor: "#FFFFFF"
    },
    rectangle: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Rectangle",
        fontColor: "#FFFFFF"
    },
    roll: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Roll",
        fontColor: "#FFFFFF"
    },
    start: {
        fill: "#616161",
        stroke: "#616161",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Start",
        fontColor: "#FFFFFF"
    },
    storage: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Storage",
        fontColor: "#FFFFFF"
    },
    subroutine: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Subroutine",
        fontColor: "#FFFFFF"
    },
    tape: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Tape",
        fontColor: "#FFFFFF"
    },
    triangle: {
        fill: "#C7755B",
        stroke: "#C7755B",
        strokeWidth: 2,
        width: 140,
        height: 90,
        text: "Triangle",
        fontColor: "#FFFFFF"
    }
};

export default DefaultsSettingForShapesType;
