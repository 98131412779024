const DiagramToolbarConfig = {
    // toolbar: true
    toolbar: {
        css: "custom_css",
        navigationType: "pointer",
        items: [
            {
                type: "file",
                items: [
                    // "importJson",
                    // {
                    //   type: "exportJson",
                    //   value: "Export to JSON"
                    // },
                    {
                        id: "custom_id_1",
                        type: "menuItem",
                        value: "Server export",
                        icon: "dxi dxi-vault",
                        items: [
                            "exportPdf",
                            {
                                id: "custom_id_2",
                                type: "menuItem",
                                value: "PNG",
                                handler: (editor: any) => {
                                    editor.diagram.export.png();
                                    // custom logic here
                                }
                            }
                        ]
                    }
                ]
            },
            {
                type: "edit",
            },
            {
                type: "view",
            },
            // {
            //   type: "arrange",
            // },
            {
                type: "spacer",
            },
            {
                type: "scale",
            },
        ]
    },

    shapebar: {
        sections: {
            "Shapes": [{ flowShapes: true }],
            // "Base org cards": [{ org: true }],
            // "Base groups": [{ group: true }],
            // "Base swimlane": [{ swimlane: true }]
        }
    }
}

export default DiagramToolbarConfig