import React from "react";
import { Bar, Line, Pie } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    registerables,
} from "chart.js";


ChartJS.register(
    ...registerables,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
);


interface ChartDashboardProps {
    type: 'bar' | 'line' | 'pie';
    data: {
        labels: string[];
        datasets: {
            label: string;
            data: number[];
            backgroundColor?: string | string[];
            borderColor?: string;
            fill?: boolean;
            tension?: number;
        }[];
    };
    options?: any;
}

const ChartDashboard: React.FC<ChartDashboardProps> = ({ type, data, options }) => {
    const renderChart = () => {
        switch (type) {
            case 'bar':
                return <Bar data={data} options={options} />;
            case 'line':
                return <Line data={data} options={options} />;
            case 'pie':
                return <Pie data={data} options={options} />;
            default:
                return null;
        }
    };

    return (
        <div className="chart-container">
            {renderChart()}
        </div>
    );
};

export default ChartDashboard;
