// React Compoment
import React from 'react'

// Features
import { Routes } from '../../routes'
import { CollaboratorsPages } from '../../features/dashboard'

// Layout
import DashLayout from '../../layouts/dashboard/dashLayout'

const CollaboratorsView = () => {
    const pathname = window.location.pathname

    return (
        <DashLayout>
            {pathname === Routes.COLLABORATORS_LIST ?
                <CollaboratorsPages />
                :
                <CollaboratorsPages />
            }

        </DashLayout>
    )
}

export default CollaboratorsView;