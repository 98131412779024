import { useState, useEffect } from 'react';
import { FamlaServices } from '../services';

import { useAppSelector } from './../stores/store';

const useSearch = (url: string, companyId?: string) => {
    const token = useAppSelector((state) => state.auth.access_token);
    const [searchQuery, setSearchQuery] = useState<string | undefined>('');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [searchLoad, setSearchLoad] = useState(false);
    const [filter, setFilter] = useState<string | undefined>('TITLE');

    useEffect(() => {
        const fetchSearchResults = async () => {
            if (searchQuery && token) {
                const requestData = {
                    search: searchQuery,
                    filter: filter,
                    company_id: companyId
                };
                let res = await FamlaServices("api_host", url, 'POST', requestData, token);
                if (res.status === 201) {
                    setSearchResults(res.body.data);
                    setSearchLoad(true);
                }
            } else {
                setSearchResults([]);
            }
        };
        fetchSearchResults();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, searchQuery, url]);

    return { searchQuery, setSearchQuery, searchResults, searchLoad, setFilter };
};
export default useSearch;
