import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


import Dropdown from '../Dropdown'
import { AssignProcessToFolderModal, EditprocessModal, GenericModal, PublishModal } from '../../views/modal'
import { useAppSelector } from '../../stores'
import { FamlaServices } from '../../services'
import { slice } from '../../utils'
import { Routes } from '../../routes'
import { iProject } from '../../interfaces'
import { iProcess } from '../type'

interface ChildProps {
    processName: string
    thisProcessHasABadge: boolean
    theBadgeHasAValue: boolean
    badgeValue: number
    owner: string
    thisProcessHasAOwner: boolean
    key: number | string
    route: string
    deleteProcess: () => void
    description: string
    processId: string
    folder: boolean
    setProject: React.Dispatch<React.SetStateAction<iProject[]>>;
    setProcess: React.Dispatch<React.SetStateAction<iProcess[]>>;
    usage?: string
}

const CardProcess = (props: ChildProps) => {
    let {
        setProject,
        setProcess,
        processId,
        processName,
        thisProcessHasABadge,
        theBadgeHasAValue,
        badgeValue,
        owner,
        thisProcessHasAOwner,
        key,
        route,
        description,
        deleteProcess,
        folder,
        usage
    } = props

    const { t } = useTranslation();
    const role = useAppSelector(state => state.role.role)
    const users = useAppSelector((state) => state.auth);
    const [interviewId, setInterviewId] = React.useState<string | undefined>()

    React.useEffect(() => {
        const getMyInterviewByProcess = async () => {
            let data = await FamlaServices("api_host", `/interview/process/${processId}`, "GET", "", users.access_token);
            if (data.status === 200) {
                setInterviewId(data.body.data._id);
            }
        }
        if (thisProcessHasAOwner) {
            getMyInterviewByProcess()
        }

    }, [processId, thisProcessHasAOwner, users.access_token])

    function routes(): string {
        let newroute: any
        if (thisProcessHasAOwner && !folder && interviewId) {
            newroute = `${(route + interviewId)}`
        } else if (folder) {
            newroute = `${(Routes.PROCESS_BY_FOLDER + processId)}`
        }
        return newroute
    }

    const handleCardClick = () => {
        if (usage === "populare") {
            window.location.href = Routes.PROCESS_CREATE;
        } else {
            window.location.href = routes();
        }

    }

    const handleDropdownClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    }

    return (
        <>
            <div
                className='mt-3'
                key={key}
                style={{ cursor: 'pointer' }}
                onClick={handleCardClick}
            >
                <div className={` cardProcess bg-black border border-white text-white d-flex flex-column align-items-center position-relative text-decoration-none ${thisProcessHasAOwner ? 'pt-2' : 'pt-3'}`} >
                    {thisProcessHasABadge &&
                        <span className={`position-absolute top-0 start-100  ${(thisProcessHasABadge && theBadgeHasAValue) ? "px-2" : ""} translate-middle bg-theme border border-0 rounded-circle`} style={{ marginLeft: -35, padding: (thisProcessHasABadge && !theBadgeHasAValue) ? "12px" : "" }}>
                            <span className="visually-hidden">New alerts</span>{theBadgeHasAValue && badgeValue}
                        </span>
                    }
                    <NavLink to={routes()} className={`padding text-decoration-none text-white`}>{slice(processName, 25)}</NavLink>
                    {description?.length > 0 && !folder && <p className='px-1 text-center text-control overflow-auto description' style={{ fontSize: 12, color: '#848484', height: '100%', width: 'auto' }}> {slice(description, 100)}</p>}
                    {thisProcessHasAOwner &&
                        <div className='d-flex justify-content-between w-100 position-absolute bottom-0 padding '>
                            <div className='text-theme'>{owner}</div>
                            {(role !== 'WORKER' && processName !== "Default") &&
                                <Dropdown
                                    dropName=''
                                    dropdownType='dropdown  dropup'
                                    iconClassName='fa fa-ellipsis-h'
                                    buttonDropClassName='btn btn-secondary bg-black border-0'
                                    styleButton={{}}
                                    action3={folder ? '' : `${t('words.organize')}`}
                                    theRouteOfAction4=''
                                    deleteProcess={deleteProcess}
                                    action1=''
                                    theRouteOfAction5=''
                                    dataBsTarget={`#asignProcessToFolder${processId}`}
                                    routeFaq=''
                                    edit={`${t('words.rename')}`}
                                    dataBsTargetEdit={`#edit${processId}`}
                                    publish={!folder ? `${t('navigation.markAsFinal')}` : ''}
                                    dataBsTargetPublish={`#publish${processId}`}
                                    onClick={handleDropdownClick}
                                    action7={t('words.delete')}
                                    dataBsTargetAction7={`#generic${processId}`}
                                />
                            }
                        </div>
                    }
                </div >
            </div >

            {(processName && processId) &&
                <AssignProcessToFolderModal
                    processId={processId}
                    processName={processName}
                />}
            {(processName && processId) &&
                <EditprocessModal
                    folder={folder}
                    processId={processId}
                    processName={processName}
                    description={description}
                    setProject={setProject}
                    setProcess={setProcess}
                />}
            {(processId) &&
                <>
                    <GenericModal
                        title={t('words.delete')}
                        actionButton={t('words.delete')}
                        action={deleteProcess}
                        sentence={t('views.processView.areYouSure')}
                        processId={processId}
                    />
                    <PublishModal processId={processId} />
                </>
            }


        </>
    )
}
export default CardProcess
