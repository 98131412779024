import React from 'react'
import { useTranslation } from 'react-i18next'

import { AppLayout } from '../../layouts'
import { Images } from '../../constants'
// import { Link } from 'react-router-dom'
import { Routes } from '../../routes'


const TermsAndCondition = () => {
    const { t } = useTranslation();
    return (
        <AppLayout>
            <div className="container-fluid fixed-top mb-3 bg-black d-flex w-100">
                <div className="text-white fs-5 mb-0  pt-2 " style={{ height: '50px' }}> <span className=""> <img className='img-fluid' src={Images.logov1} alt="" style={{ height: '30px' }} /></span></div>
            </div>
            <span className="mb-5 text-black">.</span>
            <div className="container align-items-center my-5">
                <div className="container align-items-center my-5">
                    <a href={Routes.DASHBOARD} className="text-light fw-bold fs-4 text-decoration-none text-uppercase">
                        <div className="d-flex">
                            <span><i className="fa-solid fa-arrow-left"></i></span>
                            <p className="ms-1">{t('navigation.backHome')}</p>
                        </div>
                    </a>
                    <h6 className="fw-blod fs-4  pb-3 text-theme bg-black pt-0   text-uppercase">{t('views.legalDocs.terms.terms_and_condition')}</h6>
                    <ol className="fs-6 text-white p-4">
                        <li className="text-white fs-4">{t('views.legalDocs.terms.introduction')}</li>
                        <p className="flex-wrap">{t('views.legalDocs.terms.p1')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.terms.t2')}</li>
                        <p className="flex-wrap">{t('views.legalDocs.terms.p2')}</p>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.terms.t3')}</li>
                        <div className='mt-2'>
                            <h6>3.1 {t('views.legalDocs.terms.t4')}</h6>
                            <p className='ps-4'>{t('views.legalDocs.terms.p3')}</p>
                            <h6>3.2 {t('views.legalDocs.terms.t5')}</h6>
                            <p className='ps-4'>{t('views.legalDocs.terms.p4')}</p>
                            <h6>3.3 {t('views.legalDocs.terms.t6')}</h6>
                            <span>{t('views.legalDocs.terms.youAgreeNotTo')}</span>
                            <ul className='pt-2'>
                                <li>{t('views.legalDocs.terms.ulLi1')}</li>
                                <li>{t('views.legalDocs.terms.ulLi2')}</li>
                                <li>{t('views.legalDocs.terms.ulLi3')}</li>
                                <li>{t('views.legalDocs.terms.ulLi4')}</li>
                                <li>{t('views.legalDocs.terms.ulLi5')}</li>
                                <li>{t('views.legalDocs.terms.ulLi6')}</li>
                                <li>{t('views.legalDocs.terms.ulLi7')}</li>
                                <li>{t('views.legalDocs.terms.ulLi8')}</li>
                            </ul>
                        </div>
                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.terms.t7')}</li>
                        <p>{t('views.legalDocs.terms.p5')}</p>

                        <li className="text-white mt-3 fs-4">{t('views.legalDocs.terms.t8')}</li>
                        <div>
                            <h6 className='pt-2'>5.1 {t('views.legalDocs.terms.t9')}</h6>
                            <p className='ps-4'>{t('views.legalDocs.terms.p6')}</p>
                            <h6 className='pt-2'>5.2 {t('views.legalDocs.terms.t10')}</h6>
                            <span>{t('views.legalDocs.terms.famlaMay')}</span>
                            <ul className='pt-2'>
                                <li>{t('views.legalDocs.terms.ulLi9')}</li>
                                <li>{t('views.legalDocs.terms.ulLi10')}</li>
                                <li>{t('views.legalDocs.terms.ulLi11')}</li>
                                <li>{t('views.legalDocs.terms.ulLi12')}</li>
                                <li>{t('views.legalDocs.terms.ulLi13')}</li>
                            </ul>
                            <h6 className='pt-2'>5.3 {t('views.legalDocs.terms.t11')}</h6>
                            <span>{t('views.legalDocs.terms.famlaWillTake')}</span>
                            <ul className='pt-2'>
                                <li>{t('views.legalDocs.terms.ulLi14')}</li>
                                <li>{t('views.legalDocs.terms.ulLi15')}</li>
                                <li>{t('views.legalDocs.terms.ulLi16')}</li>
                                <li>{t('views.legalDocs.terms.ulLi17')}</li>
                            </ul>
                            <h6 className='pt-2'>5.4 {t('views.legalDocs.terms.t12')}</h6>
                            <ul className='pt-2'>
                                <li>{t('views.legalDocs.terms.ulLi18')}</li>
                                <li>{t('views.legalDocs.terms.ulLi19')}</li>
                                <li>{t('views.legalDocs.terms.ulLi20')}</li>
                                <li>{t('views.legalDocs.terms.ulLi21')}</li>
                            </ul>

                            <h6 className='pt-2'>5.5 {t('views.legalDocs.terms.t13')}</h6>
                            <p className='ps-4'>{t('views.legalDocs.terms.p7')}</p>
                        </div>
                        <li className="text-white mt-3 fs-4 "> {t('views.legalDocs.terms.t14')}</li>
                        <p>{t('views.legalDocs.terms.p8')} </p>
                        <li className="text-white mt-3 fs-4"> {t('views.legalDocs.terms.t15')}</li>
                        <p>{t('views.legalDocs.terms.p9')} </p>
                        <li className="text-white mt-3 fs-4"> {t('views.legalDocs.terms.t16')}</li>
                        <p>{t('views.legalDocs.terms.p10')} </p>
                        <li className="text-white mt-3 fs-4"> {t('views.legalDocs.terms.t17')}</li>
                        <p>{t('views.legalDocs.terms.p11')} </p>
                        <li className="text-white mt-3 fs-4"> {t('views.legalDocs.terms.t18')}</li>
                        <p>{t('views.legalDocs.terms.p12')} </p>
                        <li className="text-white mt-3 fs-4"> {t('views.legalDocs.terms.t19')}</li>
                        <p>{t('views.legalDocs.terms.p13')} </p>
                        <li className="text-white mt-3 fs-4"> {t('views.legalDocs.terms.t20')}</li>
                        <p>{t('views.legalDocs.terms.p14')} </p>
                        <li className="text-white mt-3 fs-4">{t('navigation.contactUs')}</li>
                        <p>{t('views.legalDocs.terms.p15')}:  <span className='text-theme'>contact@famla.ai</span></p>
                        <p className='mt-2'><strong>{t('views.legalDocs.terms.effectiveDate')}:</strong> 2024</p>
                    </ol>
                </div>
            </div >
        </AppLayout >
    )
}
export default TermsAndCondition