import React from "react";
import { TFunction } from 'i18next';
import { Navigate, useNavigate } from "react-router-dom";

//APP
import { Button } from "../features/swimLane";
import Spiner from "./Spiner";
import { TypingParagraph } from "../utils";


interface ChildProps {
    refresh: () => void;
    error: boolean
    t: TFunction
    time?: number
}

const SwimlaneError: React.FC<ChildProps> = (props) => {
    const { error, refresh, t, time } = props;
    const navigate = useNavigate()
    const backToChatBot = () => {
        navigate(-1)

    }
    const messages: string[] = [
        t('features.swimlane.s1', { time }),
        t('features.swimlane.s2', { time }),
        t('features.swimlane.s3', { time }),
    ];


    return (
        <div className="pt-5 h-100 bg-dark m-0 smpage position-fixed w-100 ">
            {error ?
                <div className="card text-center rounded-0 bg-dark border-0">
                    <div className="card-body">
                        <span>
                            <i className="fa-solid fa-triangle-exclamation fs-1 text-white mb-5"></i>
                        </span>
                        <h5 className="card-title text-theme"> {t('features.swimlane.oops')}</h5>
                        <p className="card-text px-5 mx-5 text-white">
                            {t('features.swimlane.WeSorry')}
                        </p>
                        <div className="d-flex justify-content-center">
                            <Button className="dhx_sample-btn dhx_sample-btn--flat border-0 bg-theme text-center " name={t('words.refresh')} onClick={backToChatBot} submit={false} />
                        </div>
                    </div>
                </div>
                :
                <div className="mt-5 p-2 ">
                    <div className=" d-flex justify-content-center align-items-center text-white">
                        <div className="text-white text-start pb-2 fs-5 d-flex flex-column">
                            <span className="d-flex justify-content-center align-items-center mb-3">  <i className="fa-solid  fa-hourglass-half text-theme fs-1"></i></span>
                            <span>{t('features.swimlane.pleaseWait')}</span>
                        </div>

                    </div>
                    {time !== 0 &&
                        <div className=" d-flex justify-content-center align-items-center text-light opacity-50">
                            <TypingParagraph key={0} messages={messages} delayBetweenMessages={7000} typingSpeed={50} className='fs-6' />
                        </div>
                    }

                    <div className="d-flex justify-content-center">
                        <Spiner className="mt-5 text-white " />
                    </div>
                </div>

            }

        </div>

    );
}

export default SwimlaneError;
