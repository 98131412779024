// Recat Component
import { PURGE } from 'redux-persist'
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { iCompany, iUser } from "../../components/type"

// Interface

interface AuthState {
    company: iCompany;
}

const initialState: AuthState = {
    company: {
        _id: '',
        name: '',
        address: '',
        city: '',
        country: '',
        phone_number: '',
        postal_code: '',
        status: '',
        website: '',
        created_at: '',
        updated_at: '',
        is_deleted: false,
        owner: {} as iUser,
        last_connection: '',
        tokensRemaining: 0,
        tokensUsed: 0,
        totalTokens: 0,
    },
}

const companySlice = createSlice({
    name: "company",
    initialState,
    reducers: {
        company: (state, action: PayloadAction<{ company: iCompany }>) => {
            state.company = action.payload.company;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.company = {};
        })
    }
})

export default companySlice.reducer;
export const { company } = companySlice.actions; 
