import React from 'react'
import TextareaAutosize from 'react-textarea-autosize';


import Buttons from './Buttons'



interface textAreaProps {
    label: string
    rows: number
    classNameArea: string
    classNameContainer: string
    placeholder: string
    onChange(event: React.SyntheticEvent): any
    value: string
    interviewId: string
    sendAnswer: (e: React.MouseEvent<HTMLButtonElement>, answerId: string) => void;
    spinner: boolean
    maxRows: number
    minRows: number
    sendButton: boolean
    button: boolean
    readOnly: boolean,
    name: string

}





const TextAreaForm = (props: textAreaProps) => {
    const {
        button,
        name,
        sendButton,
        label,
        rows,
        classNameArea,
        classNameContainer,
        placeholder,
        onChange,
        value,
        interviewId,
        sendAnswer,
        spinner,
        maxRows,
        minRows,
        readOnly
    } = props

    // const [rows, setRows] = React.useState(minRows);

    // const handleInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    //     const textareaLineHeight = 24;
    //     const previousRows = event.target.rows;
    //     event.target.rows = minRows;

    //     const currentRows = Math.floor(event.target.scrollHeight / textareaLineHeight);


    //     if (currentRows >= maxRows) {
    //         event.target.rows = maxRows;
    //         event.target.scrollTop = event.target.scrollHeight;
    //     } else {
    //         event.target.rows = currentRows;
    //     }


    //     setRows(currentRows < maxRows ? currentRows : maxRows);
    //     onChange(event);
    // };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter') {
            sendAnswer(event as any, interviewId);
            event.preventDefault();
        }
    };


    return (
        // <div className={`${classNameContainer}  h-100`}>
        //     <label htmlFor="textarea" className="form-label">{label}</label>
        //     {button &&
        //         <Buttons
        //             buttonType="submit"
        //             name=""
        //             onClick={(e) => { sendAnswer(e, interviewId) }}
        //             isLoading={false}
        //             btnClass={`btn-theme ms-2 position-absolute end-0 bottom-0  me-2 ${(spinner || !sendButton) && 'disabled'} `}
        //             labelClass="text-white fw-bold"
        //             styleProps={{ height: 30, marginBottom: 35.5 }}
        //             withIcon={true}
        //             iconName={`fa-solid ${spinner && 'fa-spinner'} fa-arrow-up fs-6`}
        //             btnStyle={{ height: 58 }}
        //             arrowVisible={false}
        //             arrowColor=''
        //         />}

        //     <TextareaAutosize
        //         minRows={minRows}
        //         maxRows={maxRows}
        //         className={`border-light ${classNameArea}`}
        //         placeholder={placeholder}
        //         onChange={(event) => onChange(event)}
        //         onKeyPress={handleKeyPress}
        //         value={value}
        //         name={name}
        //         id="textarea"
        //         rows={rows}
        //         style={{ resize: 'none', overflow: 'auto' }}
        //         readOnly={readOnly}
        //         maxLength={3200}

        //     />
        //     {/* <textarea
        //         onKeyPress={handleKeyPress}
        //         rows={rows}
        //         onChange={handleInput}
        //         value={value}
        //         readOnly={readOnly}
        //         className={`border-light ${classNameArea}`}
        //         placeholder={placeholder}
        //         id="floatingTextarea"
        //         name={name}
        //         maxLength={1600}
        //         style={{ resize: 'none', overflow: 'hidden' }}
        //     />*/}
        // </div>

        <div className={`${classNameContainer}`}>
            <TextareaAutosize
                minRows={minRows}
                maxRows={maxRows}
                className={`border-light ${classNameArea}`}
                placeholder={placeholder}
                onChange={(event) => onChange(event)}
                onKeyPress={handleKeyPress}
                value={value}
                name={name}
                id="textarea"
                rows={rows}
                style={{ resize: 'none', overflow: 'auto' }}
                readOnly={readOnly}
                maxLength={3200}

            />

            {button &&
                <Buttons
                    buttonType="submit"
                    name=""
                    onClick={(e) => { sendAnswer(e, interviewId) }}
                    isLoading={false}
                    btnClass={`btn-theme ms-2 mb-1 me-2 ${(spinner || !sendButton) && 'disabled'} `}
                    labelClass="text-white fw-bold"
                    styleProps={{ height: 30, marginBottom: '0' }}
                    withIcon={true}
                    iconName={`fa-solid ${spinner && 'fa-spinner'} fa-arrow-up fs-6`}
                    btnStyle={{ height: 58 }}
                    arrowVisible={false}
                    arrowColor=''
                />}

        </div>
    )
}
export default TextAreaForm